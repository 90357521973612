<template>
  <b-row>
    <b-col md="4">
      <field-file
          name="document"
          :isRequired="true"
          :singleFile.sync="bankStatement.document"
          documentType="bankStatement"
      />
    </b-col>

    <b-col md="8">
      <b-row>

        <!-- Bank account -->
        <b-col md="4">
          <field-select
              :model.sync="bankStatement.bankAccount"
              name="bankAccount"
              :options="bankAccounts"
              :isRequired="true"
              label="_display"
          />
        </b-col>

        <!-- Start date -->
        <b-col md="4">
          <field-date
              name="startDate"
              :isRequired="true"
              :model.sync="bankStatement.startDate"
          />
        </b-col>

        <!-- End date -->
        <b-col md="4">
          <field-date
              name="endDate"
              :isRequired="true"
              :model.sync="bankStatement.endDate"
          />
        </b-col>

        <!-- starting balance -->
        <b-col md="6">
          <field-input
              name="startingBalance"
              rules="required|decimal"
              :model.sync="bankStatement.startingBalance"
          />
        </b-col>

        <!-- ending balance -->
        <b-col md="6">
          <field-input
              name="endingBalance"
              rules="required|decimal"
              :model.sync="bankStatement.endingBalance"
          />
        </b-col>
      </b-row>
    </b-col>

    <b-col cols="12">
      <b-alert
          :variant="isBalanceCorrect?'success':'danger'"
          show
          class="mb-0"
      >
        <div class="alert-body">
          <icon :icon="isBalanceCorrect?'check':'exclamation-triangle'"
                class="mr-50"/>
          <span v-if="isBalanceCorrect">{{ $t('The amount of the transactions correspond to the balance recorded') }}
          </span>
          <span v-else>{{ $t('The transaction amount does not match the reported balance') }}</span>
        </div>
      </b-alert>
    </b-col>

    <b-col
        cols="12"
        class="mt-2"
        v-if="errors.length != 0"
    >
      <b-alert
          variant="danger"
          show
          class="mb-0"
      >
        <div class="alert-body">
          <icon icon="exclamation-triangle"
                class="mr-50"/>

          <span>
            <b>{{ $t('Check the following transactions:') }}</b>
          </span>
          <ul>
            <li v-for="error in errors">
              {{ error }}
            </li>
          </ul>
          <!--          <span v-else></span>-->
        </div>
      </b-alert>
    </b-col>

    <b-col cols="12 mt-4"
           :key="componentKey">
      <transition-group name="list-complete"
                        tag="p">

        <b-row
            v-for="(bankOperation, index) in bankStatement.bankOperations"
            :key="index"
            ref="row"
            class="list-complete-item"
            :class="hasError(index)?'bg-danger bg-lighten-5':''"
        >
          <b-col cols="12">
            <bank-operation
                :bankOperation="bankOperation"
                containerSize="md"
                :index="index"
                :formErrors="formErrors"
                @update="componentKey++"
                @removeBankOperation="removeBankOperation(bankOperation._uuid)"
            />
          </b-col>

        </b-row>

      </transition-group>
    </b-col>

    <b-col cols="12">
      <button-add @click="addNewBankOperation"/>
    </b-col>

  </b-row>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'

import FieldFile from '@/components/input/File'
import FieldDate from '@/components/input/Date'
import FieldInput from '@/components/input/Input'
import FieldSelect from '../../../components/input/Select'
import BankOperation from '@/components/form/BankOperation'
import ButtonAdd from '@/components/button/Add'
import useAPI from '../../../utils/useAPI'

export default {
  components: {
    FieldFile,
    FieldDate,
    FieldInput,
    FieldSelect,
    BankOperation,
    ButtonAdd
  },
  props: {
    bankStatement: {
      type: Object,
      default: () => {}
    },
    formErrors: {
      type: Object,
      default: () => {}
    }
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const componentKey = ref(0)
    const errors = ref([])

    const localFormErrors = ref(props.formErrors)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const { bankAccounts } = useAPI()

    const measuredBalance = computed(() => {
      return parseFloat(props.bankStatement.endingBalance) - parseFloat(props.bankStatement.startingBalance)
    })

    const calculatedBalance = computed(() => {
      let output = 0

      props.bankStatement.bankOperations.forEach(bo => output += parseFloat(bo._balance))

      return output
    })

    const isBalanceCorrect = computed(() => {
      // console.log(measuredBalance.value.toFixed(2))
      // console.log(calculatedBalance.value.toFixed(2))

      return measuredBalance.value.toFixed(2) == calculatedBalance.value.toFixed(2)
    })

    // const getErrorss = computed( () => {
    //   props.bankStatement.bankOperations.
    // })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(() => props.formErrors, val => {
      errors.value = []

      for (const [key, value] of Object.entries(val)) {
        if (value.length != 0) {
          let index = parseInt(key.replace(/^\D+/g, ''))
          errors.value.push(props.bankStatement.bankOperations[index - 1].wording)
        }
      }
      // console.log(errors.value)
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const addNewBankOperation = () => {
      props.bankStatement.bankOperations.push({ debit: 0 })
      console.log(props.formErrors)
    }

    const removeBankOperation = (uuid) => {
      let position = props.bankStatement.bankOperations.findIndex(bo => bo._uuid == uuid)
      props.bankStatement.bankOperations.splice(position, 1)
      componentKey.value++
    }

    const hasError = (index) => {
      let errors = 0
      for (const property in props.formErrors) {
        if (parseInt(property.replace(/^\D+/g, '')) == (index + 1)) {
          if (props.formErrors[property].length != 0) {
            errors++
          }
        }
      }

      if (errors != 0) {
        return true
      } else {
        return false
      }
    }

    // const getErrors = (index) => {
    //   let errors = []
    //   for (const property in props.formErrors) {
    //     if (parseInt(property.replace(/^\D+/g, '')) == (index + 1)) {
    //       if (props.formErrors[property].length != 0) {
    //         console.log(props.formErrors[property])
    //
    //         console.log(props.bankStatement.bankOperations[index])
    //         errors.push(props.bankStatement.bankOperations[index].wording)
    //       }
    //     }
    //   }
    //
    //   return errors
    // }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data
      componentKey,
      errors,

      // Computed
      bankAccounts,
      isBalanceCorrect,

      // Methods
      addNewBankOperation,
      removeBankOperation,
      hasError,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>