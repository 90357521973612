<template>
  <b-modal
      id="modalBankStatement"
      :title="title"
      v-model="isOpenLocal"
      size="lg"
      scrollable
      hide-footer
      @hide="tryCloseBankStatementModal"
  >
    <validation-observer #default="{handleSubmit, errors}">
      <b-form @submit.prevent="handleSubmit(submitValidatedBankStatementLocal)">
        <form-bankStatement :bankStatement="bankStatementLocal"
                    :formErrors="errors"/>
        <button ref="submitBankStatementFormRef"
                class="d-none"></button>
<!--        {{errors}}-->
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'

import FormBankStatement from '@/components/form/bankStatement/BankStatement'

export default {
  components: {
    ValidationObserver,
    FormBankStatement
  },
  props: {
    bankStatement: {
      type: Object,
      default: () => {}
    },
    title: {
      type: String,
      default: ''
    },
    isOpen: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const isOpenLocal = ref(false)
    const bankStatementLocal = ref(props.bankStatement)
    const submitBankStatementFormRef = ref(null)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(isOpenLocal, (val) => {
      emit('update:isOpen', val)
    })
    watch(bankStatementLocal, (val) => {
      emit('update:bankStatement', val)
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const tryCloseBankStatementModal = (modalEvent) => {
      if (modalEvent.trigger == 'backdrop') {
        // Click outside
        modalEvent.preventDefault()
        submitBankStatementFormRef.value.click()

        document.getElementById("modalBankStatement").getElementsByClassName("modal-body")[0].scrollTop = 0
      }
    }

    const submitValidatedBankStatementLocal = () => {
      emit('submitValidated')
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data
      isOpenLocal,
      bankStatementLocal,
      submitBankStatementFormRef,

      // Computed

      // Methods
      tryCloseBankStatementModal,
      submitValidatedBankStatementLocal
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {
    bankStatement: function (val) {
      this.bankStatementLocal = val
    },
    isOpen: function (val) {
      this.isOpenLocal = val
    },
  },
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>